.ReceptList__list {
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  margin: 10px;
  padding: 20px;
}

.ReceptList__list__item {
  background-color: #f7f6f2;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  margin-bottom: 5px;
  cursor: pointer;
  min-width: 80%;
}

.ReceptChooseDialog__choosen--active {
  background-color: rgba(132, 155, 255, 0.3);
}