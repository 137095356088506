.listTrackingRecept__row {
    background-color: #f7f6f2;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #eee;
    transition: all 0.2s ease;
    margin-bottom: 5px;
    cursor: pointer;
}
.trackingDippForm__row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    transition: all 0.2s ease;
    margin-bottom: 5px;
}

/* TrackingFilter */
.TrackingFilter__header {
    background-color: white;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    margin: 10px 0;
    padding: 20px;
}
.TrackingFilter__menu {
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    margin: 10px;
    padding: 20px;
}
.TrackingFilter__row {
    background-color: #f7f6f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #eee;
    transition: all 0.2s ease;
    margin-bottom: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.TrackingFilter__row__item {
    width: 180px;
}
.TrackingFilter__row__item:nth-child(1) {
    width: 100px;
}
.TrackingFilter__row__item:nth-child(3) {
    width: 250px;
}
.TrackingFilter__list {
    
}



/* TrackingNav */
.trackingNav {
    display: flex;
    flex-direction: column;
}
.trackingNav__row {
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    padding: 10px 10px;
    transition: all 0.2s ease;
    margin-bottom: 5px;
    cursor: pointer;
}
.trackingNav__row:hover {
    background-color: #f7f6f2;
}