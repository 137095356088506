/* Page Layout */
.ingredients-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  height: calc(100vh - 30px); /* Account for padding */
  display: flex;
  flex-direction: column;
}

.ingredients-header {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.ingredients-header h1 {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 5px;
}

.ingredients-header p {
  color: #666;
  font-size: 1rem;
}

.ingredients-header .nav-link {
  display: inline-block;
  margin-top: 10px;
  color: #1976d2;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.ingredients-header .nav-link:hover {
  color: #1565c0;
  text-decoration: underline;
}

/* Search and Controls Section */
.ingredients-controls {
  background-color: #f7f6f2;
  padding: 12px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ingredients-controls .MuiTextField-root {
  background-color: white;
  border-radius: 4px;
  width: 300px;
}

/* List Item Styles */
.list-item {
  padding: 12px 15px;
  border: 1px solid #ddd;
  transition: all 0.2s ease;
  background-color: white;
  border-radius: 6px;
  margin: 6px 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.02);
}

.list-item:hover {
  background-color: #f5f5f5;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.list-item-content {
  display: flex;
  flex-direction: column;
}

.list-item-title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.list-item-subtitle {
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
}

/* Dialog Styles */
.ingredient-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.ingredient-dialog-title {
  background-color: #f7f6f2;
  padding: 15px 20px !important;
  border-bottom: 1px solid #ddd;
  color: #333;
}

.ingredient-dialog-content {
  padding: 20px !important;
  background-color: white;
}

.ingredient-dialog-content .MuiTextField-root {
  background-color: white;
}

.ingredient-dialog-content .MuiTableContainer-root {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.ingredient-dialog-content .MuiTable-root {
  background-color: white;
}

.ingredient-dialog-content .MuiTableHead-root {
  background-color: #f7f6f2;
}

.ingredient-dialog-content .MuiTableHead-root .MuiTableCell-root {
  font-weight: 600;
  color: #333;
}

.ingredient-dialog-content .MuiTableCell-root {
  padding: 8px 16px;
}

.ingredient-dialog-actions {
  padding: 12px 20px !important;
  background-color: white;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between !important;
  gap: 10px;
}

/* Utility Classes */
.hover-pointer {
  cursor: pointer;
}

.add-button {
  margin-left: 10px !important;
}

.flex-center {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
}

.spacer-10 {
  width: 10px;
  height: 10px;
}

.spacer-5 {
  width: 5px;
  height: 5px;
}

.spacer-20 {
  width: 20px;
  height: 20px;
}

/* Form Styles */
.CreateOrderDialog__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  gap: 12px;
}

.CreateOrderDialog__buttons {
  display: flex;
  justify-content: space-between !important;
  padding: 12px 20px;
}

/* List Container */
.IngredientList__list {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: #f7f6f2;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden; /* Ensures the border radius is visible */
}

.calculation-summary {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: right;
  margin-top: 20px;
  padding: 16px;
  background-color: #f7f6f2;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.calculation-summary div {
  font-size: 1rem;
  color: #333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.calculation-summary div::before {
  content: attr(data-label);
  font-weight: 500;
  color: #666;
}

.calculation-summary div:first-child {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1976d2;
}

.calculation-summary div:first-child::before {
  color: #1976d2;
}

.dialog-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dialog-title-content > div:first-child {
  font-size: 1.25rem;
  font-weight: 500;
}

.dialog-title-content .MuiButton-root {
  margin-left: 20px;
}

/* Print Styles */
@media print {
  @page {
    margin: 1.5cm 1cm;
  }

  /* Hide everything initially */
  body * {
    visibility: hidden;
  }

  /* Show the dialog content */
  .ingredient-dialog {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .ingredient-dialog,
  .ingredient-dialog * {
    visibility: visible;
  }

  /* Hide specific elements */
  .ingredient-dialog .ingredient-dialog-actions,
  .ingredient-dialog .MuiIconButton-root,
  .ingredient-dialog button,
  .ingredient-dialog .add-row-button {
    display: none !important;
  }

  /* Style adjustments for print */
  .ingredient-dialog-content {
    padding: 0 !important;
    max-width: none !important;
    width: 100% !important;
  }

  .ingredient-dialog-title {
    padding: 0 0 20px 0 !important;
    background: none !important;
    border: none !important;
    font-size: 24pt !important;
    font-weight: bold !important;
  }

  .MuiDialog-paper {
    max-width: none !important;
    width: 100% !important;
    margin: 0 !important;
    box-shadow: none !important;
  }

  .MuiTableContainer-root {
    width: 100% !important;
    margin-top: 20px !important;
    border: 1px solid #000 !important;
  }

  .MuiTable-root {
    width: 100% !important;
  }

  /* Column widths */
  .MuiTableCell-root:nth-child(1) { width: 40%; }  /* Ingredient name */
  .MuiTableCell-root:nth-child(2) { width: 20%; }  /* Amount */
  .MuiTableCell-root:nth-child(3) { width: 20%; }  /* Price per kg */
  .MuiTableCell-root:nth-child(4) { width: 20%; }  /* Total price */
  .MuiTableCell-root:nth-child(5) { display: none; } /* Hide actions column */

  .MuiTableCell-root {
    border-color: #000 !important;
    padding: 8px !important;
    font-size: 11pt !important;
    white-space: nowrap !important;
  }

  .calculation-summary {
    margin-top: 30px;
    border: 1px solid #000;
    background: none;
    width: 300px !important;
    margin-left: auto !important;
    padding: 12px !important;
  }

  /* Ensure text colors are dark for better printing */
  .MuiTableCell-root,
  .calculation-summary div,
  .calculation-summary div::before {
    color: #000 !important;
  }

  /* Remove unnecessary backgrounds and shadows */
  .MuiPaper-elevation1,
  .MuiDialog-paper {
    box-shadow: none !important;
    background-color: white !important;
  }

  /* Ensure the table header is visible */
  .MuiTableHead-root {
    background-color: white !important;
    border-bottom: 2px solid #000 !important;
  }

  .MuiTableHead-root .MuiTableCell-root {
    font-weight: bold !important;
    font-size: 12pt !important;
  }

  /* Adjust text field */
  .MuiTextField-root {
    display: none !important;
  }
} 