.Planner {
  background-color: white;
  margin-top: -20px;
}

.Planner__list {
  display: flex;
  justify-content: flex-start;
}

.Planner__h1 {
  color: maroon;
  font-size: x-large;
  font-weight: 700;
  text-transform: uppercase;
}

.Planner__h2 {
  font-weight: 450;
  color: #424242;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Planner__main {
  background-color: white;
  /* box-shadow: 0 2px 8px rgb(0 0 0 / 10%); */
  
  margin: 10px;
  /* height: 1200px; */
}

.Planner__main__header {
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}


.Planner__main__body {
  /* background-color: rgba(100, 148, 237, 0.377); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Planner__main__body__header {
  /* background-color: chartreuse; */
  display: flex;
  align-items: center;
}

.Planner__main__body__list {
  /* background-color: darkorange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}



.Planner__week {
  max-width: 95vw;
  display: flex;
  overflow: auto;
}

.Planner__weekday {
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
  border: 1px solid #eee;
  padding: 5px;
  margin: 10px 5px;
  border-radius: 5px;
}

.Planner__weekday__header {}

.Planner__weekday__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.Planner__weekday__body__list {}
.Planner__weekday__body__list--wide {
  display: grid;
  grid-template-columns: auto auto auto;
}

.Planner__weekday__item {
  background-color: darkolivegreen;
  background-color: #f7f6f2;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #eee;
  cursor: pointer;
  /* margin: 5px; */
  /* transform: scale(0.9); */
  color: rgba(0, 255, 17, 0.5);
}

.Planner__weekday__item--0 {
  border-left: 3px solid #eee;
}

.Planner__weekday__item--1 {
  border-left: 3px solid #2980b9;
}

.Planner__weekday__item--2 {
  border-left: 3px solid #faae33;
}

.Planner__weekday__item--3 {
  border-left: 3px solid red;
}

.Planner__weekday__item--4 {
  border: 3px solid #04a466;
}