.test__header__row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid white;
    text-transform: capitalize;
    font-weight: bold;
    font-size: large;
}
.test__row {
    display: flex;
    align-items: center;
    /* padding: 0 20px; */
    border-radius: 5px;
    border: 1px solid #eee;
    background-color: #f7f6f2;
    /* transition: all 0.2s ease; */
}
.test__row__cell {
    /* padding: 10px 20px;
    border-right: 1px solid #eee; */
}
.test {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}
.test__table {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.test__filter__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}
.test__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.test__filter select,
.test__filter button {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
}

.test__filter button {
  cursor: pointer;
  background-color: #f5f5f5;
}

.test__filter button:hover {
  background-color: #e0e0e0;
}

.test__row:hover {
  background-color: #f5f5f5;
} 