.Ordersedel {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
}

.Ordersedel__h1 {
  color: maroon;
  font-size: x-large;
  font-weight: 700;
  text-transform: uppercase;
}

.Ordersedel__h2 {
  color: maroon;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.Ordersedel__main {
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding: 20px;
  margin: 10px;
  height: 1200px;
}

.Ordersedel__main__header {
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Ordersedel__planning {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 5px;
}

.Ordersedel__planning__header {
  display: flex;
  align-items: center;
}

.Ordersedel__checklist {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding: 15px;
  border-radius: 5px;
}

.Ordersedel__checklist__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Ordersedel__checklist__header__signed {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.Ordersedel__checklist__body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.Ordersedel__main__body {
  /* background-color: cornflowerblue; */
}

.Ordersedel__main__body__header {
  /* background-color: chartreuse; */
}

.Ordersedel__main__body__list {
  /* background-color: darkorange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Ordersedel__side {
  /* background-color: red; */
  height: 100%;
}

.Ordersedel__side__inner {
  position: sticky;
  top: 30px;
}

.Ordersedel__side__header {
  /* background-color: aqua; */
}

.Ordersedel__side__header__button {
  width: 183px;
}

.Ordersedel__side__body {
  /* background-color: cornflowerblue; */
}

.Ordersedel__side__body__list {
  /* background-color: darkorange; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Ordersedel__side__body__list__item {
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  cursor: pointer;
  width: 160px;
  padding: 10px;
  margin: 5px 0px;
  transition: border 0.2 ease;
  border-left: 3px solid white;
}

/* Order */
.Order__Wrapper {
  position: relative;
}

.Order {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  margin-bottom: 5px;
}

.Order--unchecked {
  background-color: #f7f6f2;
  transition: all 0.2s ease;
}

.Order--checked {
  background-color: rgba(132, 155, 255, 0.3);
  transition: all 0.2s ease;
}

.Order__StatusButton {
  text-align: center;
  border-radius: 5px;
  padding: 7px;
  width: 100px;
  color: white;
}

.Order__Overlay {
  position: absolute;
  background-color: rgba(144, 144, 144, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.CreateOrderDialog__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.CreateOrderDialog__buttons {
  display: flex;
  justify-content: space-between !important;
}

.OrdersInactive {
  display: flex;
  align-items: center;
}