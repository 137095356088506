/* 
    orange: #e67e2
    green: #04a466
    green2: #1abc9c
    blue: #2980b9
    blue-highlight: #3399dd
    yellow: #faae33
    grey: #95a5a6
*/


.aui * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', Arial, sans-serif;
    transition: all 0.3s ease-out;
}

.aui {
    height: 100%;
    /* width: 100%; */
}

h1 {

}

.aui input {
    padding: 10px 15px;
    border: solid 1px #c5c5c5;
    font-size: medium;
}
input[type="text"] {}

.aui textarea {
    padding: 10px 15px;
    border: solid 1px #c5c5c5;
    font-size: medium;
}

.aui select {
    padding: 10px 15px;
    border: solid 1px #c5c5c5;
    font-size: medium;
}
.aui select option {
    padding: 10px 15px;
    border: solid 1px #c5c5c5;
    font-size: medium;
}



/* BUTTONS */
/* default */
.aui button.default {
    padding: 10px 15px;
    font-size: medium;
    color: white;
    cursor: pointer;
    border: 2px solid transparent;
    font-weight: bold;
    text-transform: uppercase;
}
.aui button.default:hover {
    transform: translateY(-1px);
}
.aui button.default:active {
    transform: translateY(0px);
}


/* variants */
/* contained */
/* colors */
/* primary */
.aui button.contained.primary {
    background-color: #2980b9;
}
.aui button.contained.primary:hover {
    background-color: #3399dd;
}
.aui button.contained.primary:active {
    background-color: #2980b9;
}

/* secondary */
.aui button.contained.secondary {
    background-color: #95a5a6;
}
.aui button.contained.secondary:hover {
    background-color: #bdc3c7;
}
.aui button.contained.secondary:active {
    background-color: #95a5a6;
}

/* success */
.aui button.contained.success {
    background-color: #04a466;
}
.aui button.contained.success:hover {
    background-color: #1abc9c;
}
.aui button.contained.success:active {
    background-color: #04a466;
}

/* warning */
.aui button.contained.warning {
    background-color: #e67e22;
}
.aui button.contained.warning:hover {
    background-color: #faae33;
}
.aui button.contained.warning:active {
    background-color: #e67e22;
}

/* alert */
.aui button.contained.alert {
    background-color: #e74c3c;
    color: white;
}
.aui button.contained.alert:hover {
    background-color: #ff6666;
}
.aui button.contained.alert:active {
    background-color: #e74c3c;
}

/* outlined */
/* primary */
.aui button.outlined.primary {
    color: #2980b9;
    border: 2px solid #2980b9;
    background-color: transparent;
}
.aui button.outlined.primary:hover {
    color: #3399dd;
    border: 2px solid #3399dd;
    background-color: transparent;
}

/* secondary */
.aui button.outlined.secondary {
    color: #95a5a6;
    border: 2px solid #95a5a6;
    background-color: transparent;
}
.aui button.outlined.secondary:hover {
    color: #bdc3c7;
    border: 2px solid #bdc3c7;
    background-color: transparent;
}

/* success */
.aui button.outlined.success {
    color: #04a466;
    border: 2px solid #04a466;
    background-color: transparent;
}
.aui button.outlined.success:hover {
    color: #1abc9c;
    border: 2px solid #1abc9c;
    background-color: transparent;
}

/* warning */
.aui button.outlined.warning {
    color: #e67e22;
    border: 2px solid #e67e22;
    background-color: transparent;
}
.aui button.outlined.warning:hover {
    color: #faae33;
    border: 2px solid #faae33;
    background-color: transparent;
}

/* alert */
.aui button.outlined.alert {
    color: #e74c3c;
    border: 2px solid #e74c3c;
    background-color: transparent;
}
.aui button.outlined.alert:hover {
    color: #ff6666;
    border: 2px solid #ff6666;
    background-color: transparent;
}

/* text */
/* primary */
.aui button.text.primary {
    color: #2980b9;
    background-color: transparent;
}
.aui button.text.primary:hover {
    color: #3399dd;
    background-color: transparent;
}

/* secondary */
.aui button.text.secondary {
    color: #95a5a6;
    background-color: transparent;
}
.aui button.text.secondary:hover {
    color: #bdc3c7;
    background-color: transparent;
}

/* success */
.aui button.text.success {
    color: #04a466;
    background-color: transparent;
}
.aui button.text.success:hover {
    color: #1abc9c;
    background-color: transparent;
}

/* warning */
.aui button.text.warning {
    color: #e67e22;
    background-color: transparent;
}
.aui button.text.warning:hover {
    color: #faae33;
    background-color: transparent;
}

/* alert */
.aui button.text.alert {
    color: #e74c3c;
    background-color: transparent;
}
.aui button.text.alert:hover {
    color: #ff6666;
    background-color: transparent;
}






/* table */
.aui table {
    border-collapse: collapse;
    width: 100%;
}
.aui table th, .aui table td {
    padding: 10px 15px;
    border: solid 1px #c5c5c5;
    font-size: medium;
}
.aui table th {
    background-color: #f7f6f2;
    font-weight: bold;
    text-align: left;
}
.aui table tr:nth-child(even) td {
    background-color: #f2f2f2;
}
/* .aui table tr:hover td {
    background-color: #ddd;
}
.aui table tr:active td {
    background-color: #ccc;
} */