.Whiteboard {
  background-color: white;
  display: flex;
  justify-content: center;
}

.Whiteboard__h1 {
  color: maroon;
  font-size: x-large;
  font-weight: 700;
  text-transform: uppercase;
}

.Whiteboard__h2 {
  font-weight: 450;
  color: #424242;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Whiteboard__main {
  background-color: white;
  /* box-shadow: 0 2px 8px rgb(0 0 0 / 10%); */
  padding: 0px 20px;
  margin: 10px;
  /* height: 1200px; */
}

.Whiteboard__main__header {
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}


.Whiteboard__main__body {
  /* background-color: rgba(100, 148, 237, 0.377); */
}

.Whiteboard__main__body__header {
  /* background-color: chartreuse; */
  text-align: center;
}

.Whiteboard__main__body__list {
  /* background-color: darkorange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}



.Whiteboard__week {
  max-width: 95vw;
  display: flex;
  overflow: auto;
}

.Whiteboard__weekday {
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
  border: 1px solid #eee;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  min-width: 60vw;
  font-size: larger;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}