.PersonsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PersonsList__header {
  display: flex;
  align-items: center;
}

.PersonsList__body__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.PersonsList__body__item--name {
  width: 100px;
}