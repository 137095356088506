

html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h5,
h6,
p {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.flex {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}


.MuiDialog-paperWidthSm {
  max-width: 1000px !important;
}

.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  padding-left: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.p-30 {
  padding: 30px;
}

.p-20 {
  padding: 20px;
}

.p-10 {
  padding: 10px;
}

.p-5 {
  padding: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.w-180 {
  width: 180px;
}

.w-138 {
  width: 138px;
}

.datePicker {
  padding: 8px;
}

.small_button {
  background-color: #3f51b5;
  color: #fff;
  padding: 6px;
  font-size: 0.875rem;
  width: 48px;
  height: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 50%;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  text-align: center;
}

.g-10 {
  gap: 10px;
}
.g-15 {
  gap: 15px;
}
.g-20 {
  gap: 20px;
}
.g-30 {
  gap: 30px;
}