.Nav_Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  z-index: 100;

  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 8px;
  transition: 0.4s transform ease;
}

.Nav_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.Nav_Link {
  margin-top: 10px;
}

.Nav_Controller {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background-color: rgba(221, 221, 221, 0.5);
}