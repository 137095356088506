.App {
  /* background-color: blue; */
  /* display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column; */
}

.h6 {
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-bottom: 0rem !important;
}

.spacer-3 {
  margin: 3px;
}
.spacer-5 {
  margin: 5px;
}

.spacer-10 {
  margin: 10px;
}

.spacer-15 {
  margin: 15px;
}

.spacer-20 {
  margin: 20px;
}

.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  min-width: 40px !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.Nav__wrapper > .MuiIconButton-root {
  padding: 0 !important;
}