* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body, #root {
    height: 100%;
}

body {
    padding: 0 !important;
    overflow: auto !important;
}

.background {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #f7f6f2;
    color: black;
}

.paper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid silver;
}

h2.action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: #f7f6f2;
    padding: 20px;
}
.dashboard > .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}