.FrysDialog__item {
  display: flex;
  align-items: center;
}

.FrysDialog__item__FormWrapper {
  
}

.FrysDialog__DialogContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FrysList__list__item {
  background-color: #f7f6f2;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  margin-bottom: 5px;
}