.freezer_todo {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
}   

.freezer_todo .MuiInputBase-input, .freezer_todo_view .MuiInputBase-input {
    color: black !important;
}

.freezer_todo .item, .freezer_todo_view .item  {
    display: flex;
    flex-direction: column;
    gap: 15px;
}   

.freezer_todo .item .content, .freezer_todo_view .item .content {
    min-width: 300px;
    padding: 10px;
    background-color: #f7f6f2;
    border: 1px solid #ccc;
    border-radius: 5px;
} 

.freezer_todo .item .content p.author, .freezer_todo_view .item .content p.author {
    font-size: 0.8rem;
    font-weight: 600;
    color: #0000008f;
} 

.freezer_todo .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    opacity: 0;
    max-height: 0;
    transition: all 0.2s ease-out;
}
.freezer_todo .action.active {
    opacity: 1;
    max-height: 100px;
    margin-bottom: 20px;
}