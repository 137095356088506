
.Butikslager__row {
    background-color: #f7f6f2;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #eee;
    transition: all 0.2s ease;
    margin-bottom: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.Butikslager__row__item {
    min-width: 100px;
}

.butikslager__checkout__backwrapper {
}
.butikslager__checkout__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.butikslager__checkout {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-top: 30px;
}
.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.Mui-disabled {
    -webkit-text-fill-color: black !important;
    color: black !important;
}