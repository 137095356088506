.Cooks {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.Cooks__h1 {
  color: maroon;
  font-size: x-large;
  font-weight: 700;
  text-transform: uppercase;
}

.Cooks__h2 {
  color: maroon;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.Cooks__main {
  min-width: 80%;
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding: 0px 20px;
  margin: 10px;
  height: 1200px;
}

.Cooks__main__header {
  /* background-color: aqua; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}


.Cooks__main__body {
  /* background-color: cornflowerblue; */
}

.Cooks__main__body__header {
  /* background-color: chartreuse; */
}

.Cooks__main__body__list {
  /* background-color: darkorange; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cook {
  background-color: #f7f6f2;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  margin-bottom: 5px;
}

.Cook__Wrapper {
  position: relative;
}

.Cook__Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 100;
}

.CreateCookDialog__button {
  text-align: center;
  border-radius: 5px;
  padding: 7px;
  width: 100px;
  color: white;
  cursor: pointer;
}


/* Planner */
.CookDialogReturn {
  display: flex;
  flex-direction: column;
}

.CookDialogContent {
  background-color: #f7f6f2;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.2s ease;
  margin-bottom: 5px;
}


/* Tracking */
.CookDialog__tracking {
  display: flex;
  flex-direction: column;
}

.CookDialog__tracking__list {
  background-color: #f7f6f2;
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
}